<template>
  <div>
    <div class="d-flex flex-row mb-6">
      <p class="text-2xl mb-6">Sites matchés</p>
    </div>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <v-autocomplete
              v-model="partnerSelected"
              clearable
              :items="partnerList"
              label="Filtrer sur un partenaire"
              item-text="name"
              item-value="id"
              class="select-partner shrink"
              @change="debouncedGetData"
              dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              v-model="siteSelected"
              clearable
              :items="siteList"
              label="Filtrer sur un site"
              item-text="name"
              item-value="id"
              class="select-partner shrink"
              @change="debouncedGetData"
              dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="searchStr"
              label="Rechercher"
              @input="debouncedGetData"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>

        <span v-if="!loading && data.length === 0">{{ noDataText }}</span>
        <v-data-table
          v-show="loading || data.length > 0"
          locale="fr-FR"
          :page="options.page"
          :pageCount="numberOfPages"
          :headers="headers"
          :items="data"
          :sort-by="'id'"
          :sort-desc="false"
          :options.sync="options"
          :server-items-length="total"
          :loading="loading"
          class="elevation-1"
          :footer-props="{
            'items-per-page-options': [15, 25, 50],
          }"
          :items-per-page="options.itemsPerPage"
          dense
        >
          <template v-slot:item.identifier="{ item }">
            <div>
              <span v-if="item.identifier == item.comment">
                {{ item.identifier }}
              </span>
              <span v-else> {{ item.identifier }} / {{ item.comment }} </span>
            </div>
          </template>
          <template v-slot:item.site_id="{ item }">
            <div v-if="item.site_id == '6'">Non matché</div>
            <div v-else>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                    v-html="item.site_domain"
                  ></span>
                </template>
                <span>{{ item.site_name }} (id:{{ item.site_id }})</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:item.match_status="{ item }">
            <div v-if="item.match_status == 'unmatched'">Non matché</div>
            <div v-else-if="item.match_status == 'matched'">Matché</div>
            <div v-else-if="item.match_status == 'archived'">Archivé</div>
            <div v-else>Status indéfini</div>
          </template>
          <template v-slot:item.actions="{ item }">
            <div v-if="item.match_status == 'unmatched'">
              Problème: Status non prévu
            </div>
            <div v-else-if="item.match_status == 'matched'">
              <v-btn
                :data-id="item.id"
                v-on:click="unmatch"
                color="primary"
                x-small
                elevation="4"
                class="mx-2"
              >
                Unmatch
              </v-btn>
              <v-btn
                :data-id="item.id"
                v-on:click="unmatchAndArchive"
                color="primary"
                x-small
                elevation="4"
                class="mx-2"
              >
                Unmatch and archive
              </v-btn>
            </div>
            <div v-else-if="item.match_status == 'archived'">
              <v-btn
                :data-id="item.id"
                v-on:click="unmatch"
                color="primary"
                x-small
                elevation="4"
                class="mx-2"
              >
                Reset
              </v-btn>
            </div>
            <div v-else>Problème: Status non prévu</div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-snackbar
      v-model="isSnackbarVisible"
      :timeout="2000"
      top
      :color="snackbarColor"
    >
      {{ snackbarText }}
    </v-snackbar>
    <v-dialog v-model="dialogConfirmVisible" max-width="800">
      <v-card>
        <v-card-title class="text-h5">
          {{ dialogConfirmTitle }}
        </v-card-title>
        <v-card-text>
          {{ dialogConfirmText }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" @click="dialogConfirmCallback">
            Oui
          </v-btn>
          <v-btn color="green darken-1" @click="dialogConfirmVisible = false">
            Abandonner
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ref } from "@vue/composition-api";
import { mdiPlus, mdiPencil, mdiDelete, mdiCancel } from "@mdi/js";
import axios from "@axios";
import debounce from "lodash/debounce";

import { noDataText } from "@/utils/constants";
import { formatCurrency } from "@/utils/formatting";

export default {
  name: "SitesMatchedList",
  setup() {
    const isSnackbarVisible = ref(false);
    return {
      isSnackbarVisible,
    };
  },
  data() {
    return {
      noDataText,
      optionsInitialized: false,
      partnerList: [],
      partnerSelected: null,
      siteList: [],
      siteSelected: null,
      searchStr: "",
      expanded: [1],
      loading: true,
      numberOfPages: 0,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 15,
      },
      snackbarColor: "info", // info, primary, success, warning, error
      snackbarText: "",
      dialogConfirmVisible: false,
      dialogConfirmTitle: "",
      dialogConfirmText: "",
      dialogConfirmCallbackFunction: null,
      data: [],
      headers: [
        {
          text: "Id",
          align: "start",
          value: "id",
        },
        {
          text: "Partner",
          value: "partner_name",
        },
        {
          text: "Account",
          value: "partner_account_name",
        },
        {
          text: "Identifier (/ Comment)",
          value: "identifier",
        },
        {
          text: "Site",
          value: "site_id",
        },
        {
          text: "Status",
          value: "match_status",
        },
        {
          text: "CA / 30j",
          value: "ca",
        },
        {
          value: "actions",
        },
      ],
      icons: {
        mdiPlus,
        mdiPencil,
        mdiDelete,
        mdiCancel,
      },
    };
  },
  async created() {
    this.$store.commit("appConfig/UPDATE_APP_CONTENT_WIDTH", "fullWidth");
    this.partnerList = await this.$store.dispatch("premium/getPartnersList");
    this.siteList = await this.$store.dispatch("common/getSiteList");
  },
  beforeDestroy() {
    this.$store.commit("appConfig/UPDATE_APP_CONTENT_WIDTH", "boxed");
  },
  mounted() {
    this.debouncedGetData();
  },
  methods: {
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async unmatch(e) {
      const id = e.currentTarget.getAttribute("data-id");
      const params = {
        id,
      };
      const response = await axios.post(`/partner_sites/unmatch`, params);
      if (response.status === 200) {
        this.showSnackBar("Unmatch successfully done", "success");
        this.debouncedGetData();
      } else {
        this.showSnackBar("An error occurred.", "error");
      }
    },
    async unmatchAndArchive(e) {
      const id = e.currentTarget.getAttribute("data-id");
      const params = {
        id,
      };
      const response = await axios.post(
        `/partner_sites/unmatch-and-archive`,
        params
      );
      if (response.status === 200) {
        this.showSnackBar("Unmatch and archive successfully done", "success");
        this.debouncedGetData();
      } else {
        this.showSnackBar("An error occurred.", "error");
      }
    },
    formatData(items) {
      return items.map((item) => ({
        ...item,
        ca: formatCurrency(item.ca),
      }));
    },
    async getData() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const queryParams = {
        partner_id: this.partnerSelected,
        site_id: this.siteSelected,
        search: this.searchStr,
        page: page - 1,
        limit: itemsPerPage,
        ...(sortBy && sortDesc && sortBy.length > 0 && sortDesc.length > 0
          ? {
              sort_by: sortBy[0],
              sort_desc: sortDesc[0],
            }
          : { sort_by: "id", sort_desc: false }),
      };
      const { data } = await axios.get("/partner_sites/matched", {
        params: queryParams,
      });
      this.data = this.formatData(data.items);
      this.total = data.total;
      this.numberOfPages = data.total / this.options.itemsPerPage;
      this.loading = false;
    },
    showSnackBar(text, color = "info") {
      this.snackbarColor = color; // info, primary, success, warning, error
      this.snackbarText = text;
      this.isSnackbarVisible = true;
    },
    dialogConfirmCallback() {
      this.dialogConfirmVisible = false;
      if (this.dialogConfirmCallbackFunction) {
        this.dialogConfirmCallbackFunction();
      }
      this.dialogConfirmCallbackFunction = null;
    },
    showDialogConfirm(title, text, callbackFunction) {
      this.dialogConfirmTitle = title;
      this.dialogConfirmText = text;
      this.dialogConfirmCallbackFunction = callbackFunction;
      this.dialogConfirmVisible = true;
    },
  },
  watch: {
    options(newValue, oldValue) {
      if (this.optionsInitialized) {
        // Not on initialization : wait for a real user change
        this.debouncedGetData();
      }

      this.optionsInitialized = true;
    },
  },
};
</script>

<style scoped>
.select-year {
  width: 30px;
}
.select-year {
  width: 250px;
}
th {
  text-transform: capitalize;
  font-size: 11px;
}
.cell span {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
td.cell span {
  font-size: 0.8em;
}
td.cell.value_title span,
td.cell.value_meta_description span {
  display: block;
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
td.cell.field_redac_name > span.more.redac-email {
  display: block;
  font-size: 0.8em;
}
td {
  z-index: 0;
}
.value_created,
.value_redac_name,
.value_title {
  position: sticky;
  width: 5em;
  left: 0;
  top: auto;
  z-index: 12;
  background-color: white;
}
b.red {
  color: red;
}
</style>
